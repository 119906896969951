import { FC, Fragment, useEffect } from 'react';
import Header from '../../components/Header';
import Catalog from '../../components/Service/Catalog';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateType } from 'store';
import { getCategories } from '../../modules/Catalog';

const CatalogPage: FC = () => {
    const categories = useSelector(
        (state: AppStateType) =>
            state.catalog.categories[state.catalog.currentMode]
    );
    const currentMode = useSelector(
        (state: AppStateType) => state.catalog.currentMode
    );
    const dispatch = useDispatch();
    const language = useSelector(
        (state: AppStateType) => state.catalog.currentLanguageId
    );
    useEffect(() => {
        if (!categories) dispatch(getCategories());
    }, [currentMode, language]);

    return (
        <Fragment>
            <div className={'flex-container-wrapper'}>
                <Header />
                <Catalog />
            </div>
        </Fragment>
    );
};

export default CatalogPage;
