import { FC, useState } from 'react';
import './style.scss';
import '../style.scss';
import CatalogButtons from './CatalogButtons';
import CatalogButtonsSmall from './CatalogButtonsSmall';
import { ReactComponent as Channels } from 'assets/img/catalog/Channels.svg';
import { ReactComponent as Groups } from 'assets/img/catalog/Groups.svg';
import MyCatalog from './MyCatalog';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateType } from 'store';
import CatalogPopular from './CatalogPopular';
import {
    CatalogMainTabs,
    setCatalogMode,
    setCatalogTab,
} from 'modules/Catalog';
import CategoriesTagsChannels from './CategoriesTagsChannels';
import LanguageSelector from './LanguageSelector';

const Catalog: FC = () => {
    const [selectedMainTab, setSelectedMainTab] = useState<CatalogMainTabs>(
        useSelector((state: AppStateType) => state.catalog.currentMode)
    );
    const selectedIndexSecond = useSelector(
        (state: AppStateType) => state.catalog.catalogTabIndex
    );

    const currentMode = useSelector(
        (state: AppStateType) => state.catalog.currentMode
    );

    const categoriesTags = useSelector(
        (state: AppStateType) =>
            state.catalog.categories[state.catalog.currentMode]
    );

    const isMy = selectedIndexSecond === 2;
    const isPopular = selectedIndexSecond === 1;
    const isAll = selectedIndexSecond === 0;

    const dispatch = useDispatch();

    return (
        <div className={'catalog service-wrapper'}>
            <div className={'title'}>Catalog</div>

            <CatalogButtons
                buttons={[
                    {
                        tab: CatalogMainTabs.channel,
                        button: (
                            <div>
                                <Channels /> <div>Channels</div>
                            </div>
                        ),
                    },
                    {
                        tab: CatalogMainTabs.group,
                        button: (
                            <div>
                                <Groups />
                                <div>Groups</div>
                            </div>
                        ),
                    },
                ]}
                selectedTab={selectedMainTab}
                setSelectedTab={(index: CatalogMainTabs) => {
                    dispatch(setCatalogMode(index));
                    setSelectedMainTab(index);
                }}
            />

            {isMy ? (
                <MyCatalog />
            ) : (
                <div className={'wrapper'}>
                    <div className={'language-tabs-wrapper'}>
                        <div className={'languages'}>
                            <LanguageSelector />
                        </div>
                        <div className={'tabs'}>
                            <CatalogButtonsSmall
                                buttons={['All', 'Popular']}
                                selectedIndex={selectedIndexSecond}
                                setSelectedIndex={(index: number) =>
                                    dispatch(setCatalogTab(index))
                                }
                            />
                        </div>
                        <div className={'empty'} />
                    </div>

                    {isAll ? (
                        <CategoriesTagsChannels categories={categoriesTags} />
                    ) : null}

                    {isPopular ? <CatalogPopular /> : null}
                </div>
            )}
        </div>
    );
};

export default Catalog;
