import { FC } from 'react';
import './style.scss';
import cancel from 'assets/img/cancel.svg';
import { STORAGE_TOKENS, STORAGE_USER } from 'config';
import { ROUTE_CATALOG } from 'routes/names';
import { ReactComponent as Catalog } from 'assets/img/buttons/catalog.svg';
import { ReactComponent as Neurobot } from 'assets/img/buttons/Neurobot.svg';
import { ReactComponent as Wallet } from 'assets/img/buttons/Wallet.svg';
import { ReactComponent as Logout } from 'assets/img/buttons/logout.svg';
import { useHistory } from 'react-router-dom';
import { HeaderUserDropdownProps } from './types';

const HeaderUserDropdown: FC<HeaderUserDropdownProps> = ({
    account,
    close,
}) => {
    const history = useHistory();
    const logout = () => {
        localStorage.removeItem(STORAGE_USER);
        localStorage.removeItem(STORAGE_TOKENS);
        window.location.reload();
    };

    return (
        <div className="dropdown-list" onClick={(e) => e.stopPropagation()}>
            <div className={'login-creds-wrapper'}>
                <div className={'login-creds'}>
                    <div className={'name'}>
                        {account.first_name + ' ' + account.last_name}
                    </div>
                    <div className={'link'}>{'@' + account.username}</div>
                </div>
                <img src={cancel} alt={'close'} onClick={() => close()} />
            </div>
            <div
                className={'item-wrapper'}
                onClick={() => history.push(ROUTE_CATALOG)}
            >
                <div className="dropdown-item">
                    <Catalog />
                    Catalog
                </div>
            </div>
            <div className={'item-wrapper'}>
                <div className="dropdown-item">
                    <Neurobot />
                    NFT-Store
                </div>
            </div>
            <div className={'item-wrapper'} onClick={() => logout()}>
                <div className="dropdown-item out">
                    <Logout />
                    Sign Out
                </div>
            </div>
        </div>
    );
};

export default HeaderUserDropdown;
