import { FC } from 'react';
import './style.scss';
import '../../style.scss';
import classNames from 'classnames';
import { CatalogMainTabs } from 'modules/Catalog';

interface ICatalogButtons {
    buttons: Array<{ tab: CatalogMainTabs; button: JSX.Element | string }>;
    selectedTab: CatalogMainTabs;
    setSelectedTab: (index: CatalogMainTabs) => void;
}

const CatalogButtons: FC<ICatalogButtons> = ({
    buttons,
    selectedTab,
    setSelectedTab,
}) => {
    return (
        <div className={'catalog-buttons'}>
            {buttons.map((i, index) => (
                <div
                    key={index}
                    className={classNames({
                        'catalog-button': true,
                        selected: selectedTab === i.tab,
                    })}
                    onClick={() => setSelectedTab(i.tab)}
                >
                    {i.button}
                </div>
            ))}
        </div>
    );
};

export default CatalogButtons;
