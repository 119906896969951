import { FC, useEffect } from 'react';
import Select from 'react-select';
import { selectLanguageStyles } from 'styles';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateType } from 'store';
import { getLanguages, setCurrentLanguage } from 'modules/Catalog';
import { STORAGE_LANGUAGE } from 'config';

const LanguageSelector: FC = () => {
    const dispatch = useDispatch();
    const languages = useSelector(
        (state: AppStateType) => state.catalog.languages
    );

    useEffect(() => {
        if (languages.length === 0) dispatch(getLanguages());
    }, []);

    const options = [
        { label: 'All languages', value: null },
        ...languages.map((i) => {
            return { value: i.id, label: i.nativeTitle };
        }),
    ];

    const defaultLanguage = localStorage.getItem(STORAGE_LANGUAGE)
        ? JSON.parse(localStorage.getItem(STORAGE_LANGUAGE)!)
        : { label: 'All languages', value: null };
    const currentLanguageId = useSelector(
        (state: AppStateType) => state.catalog.currentLanguageId
    );
    return (
        <div>
            <Select
                styles={selectLanguageStyles}
                options={options}
                defaultValue={defaultLanguage}
                components={{
                    IndicatorSeparator: () => null,
                }}
                isSearchable={false}
                onChange={(e) => {
                    if (e?.value !== currentLanguageId) {
                        dispatch(setCurrentLanguage(e?.value || null));
                        localStorage.setItem(
                            STORAGE_LANGUAGE,
                            JSON.stringify({ id: e?.value, label: e?.label })
                        );
                    }
                }}
            />
        </div>
    );
};

export default LanguageSelector;
