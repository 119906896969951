import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'components/form/Button';
import { useSelector } from 'react-redux';

import { ROUTE_MAIN } from 'routes/names';

import logo from 'assets/img/logo-full.svg';
import tick from 'assets/img/tick-white.svg';

import './style.scss';

interface IHeaderService {
    modalShow: (show: boolean) => void;
}

const HeaderService: FC<IHeaderService> = ({ modalShow }) => {
    const history = useHistory();
    const [walletAddress] = useSelector((state: any) => [
        state.services.wallet,
    ]);

    return (
        <div className={'header-staking'}>
            <div className={'logo-holder'}>
                <img
                    src={logo}
                    alt={'logo'}
                    className={'logo'}
                    onClick={() => history.push(ROUTE_MAIN)}
                />
                <div className={'name'}>Messenger & Crypto Wallet</div>
            </div>

            <Button isBlueFill onClick={() => modalShow(true)}>
                {walletAddress?.length ? (
                    <>
                        {walletAddress.substring(0, 6) +
                            '...' +
                            walletAddress.slice(-4)}{' '}
                        <img src={tick} alt={'wallet'} />
                    </>
                ) : (
                    'Connect Wallet'
                )}{' '}
            </Button>
        </div>
    );
};

export default HeaderService;
