import { FC } from 'react';
import './style.scss';
import '../../style.scss';
import animation from 'assets/animation/emptyData.json';
import Lottie from 'lottie-react';

const EmptyContent: FC = () => {
    return (
        <div className={'empty-content'}>
            <Lottie
                animationData={animation}
                loop={true}
                autoplay={true}
                rendererSettings={{
                    preserveAspectRatio: 'xMidYMid slice',
                }}
                style={{ height: 220, width: 220 }}
            />

            <div className={'text'}>Sorry, no results for your request!</div>
        </div>
    );
};

export default EmptyContent;
