import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    ROUTE_DOWNLOAD_ANDROID_APK,
    ROUTE_DOWNLOAD_DESKTOP_LINUX,
    ROUTE_DOWNLOAD_DESKTOP_MAC, ROUTE_DOWNLOAD_DESKTOP_WIN, ROUTE_DOWNLOAD_DESKTOP_WIN_64,
    ROUTE_DOWNLOAD_DESKTOP_WIN_64_PORTABLE, ROUTE_DOWNLOAD_DESKTOP_WIN_PORTABLE
} from "../../routes/names";

interface RouteLinks {
    [key: string]: string;
}

const baseUrl = process.env.REACT_APP_DOWNLOAD_BASE_URL;
const routeLinks: RouteLinks = {
    [ROUTE_DOWNLOAD_ANDROID_APK]: `${baseUrl}/uploads/iMe.apk`,
    [ROUTE_DOWNLOAD_DESKTOP_MAC]: `${baseUrl}/uploads/iMeInstaller.dmg`,
    [ROUTE_DOWNLOAD_DESKTOP_LINUX]: `${baseUrl}/uploads/iMe_Desktop_Linux.tar.xz`,
    [ROUTE_DOWNLOAD_DESKTOP_WIN_64_PORTABLE]: `${baseUrl}/uploads/iMe_Win_x64_portable.zip`,
    [ROUTE_DOWNLOAD_DESKTOP_WIN_PORTABLE]: `${baseUrl}/uploads/iMe_Win_x86_portable.zip`,
    [ROUTE_DOWNLOAD_DESKTOP_WIN_64]: `${baseUrl}/uploads/ime-setup-x64.exe`,
    [ROUTE_DOWNLOAD_DESKTOP_WIN]: `${baseUrl}/uploads/ime-setup-x86.exe`,
};

export default function DownloadAppsDeepLink() {
    const history = useHistory();
    const location = useLocation();

    React.useEffect(() => {
        const redirectUrl: string = routeLinks[location.pathname] || '/';

        if (redirectUrl.startsWith('http')) {
            window.location.href = redirectUrl;
        } else {
            history.push(redirectUrl);
        }
    }, [location, history]);

    return null;
}