import { FC } from 'react';
import './style.scss';
import classNames from 'classnames';

interface IToast {
    isError?: boolean;
    isHidden?: boolean;
    isSuccess?: boolean;
    isSent?: boolean;
}

export const Toast: FC<IToast> = ({
    children,
    isError,
    isHidden,
    isSuccess,
    isSent,
}) => {
    return (
        <div
            className={classNames({
                toast: true,
                'toast--error': isError,
                'toast--hidden': isHidden,
                'toast--success': isSuccess,
                'toast--sent': isSent,
            })}
        >
            {children}
        </div>
    );
};
