import { FC, useEffect } from 'react';
import './style.scss';
import '../style.scss';
import { Toast } from '../Staking/Toast';
import infoPic from 'assets/img/exclamation-circle.svg';
import { Button } from '../../form/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getClaim, loadContractInfo } from 'modules/Claim';
import { AppStateType } from 'store';
import { BNToNumstr, currentNetwork } from 'utils';
import { ReactComponent as Bep20 } from 'assets/img/bnbLime.svg';
import 'skeleton-screen-css';
import errorPic from 'assets/img/x-circle.svg';
import tickCircle from 'assets/img/tick-circle-white.svg';
import { claimConfig } from 'claimConfig';
import { COIN_LIME, PROJECT_SHORT_NAME } from 'config';
import { openNewTab } from 'utils';

interface IClaim {
    modalShow: (show: boolean) => void;
}

const Claim: FC<IClaim> = ({ modalShow }) => {
    const dispatch = useDispatch();

    const [
        limeAvailable,
        limeClaimed,
        isLoading,
        error,
        isSuccess,
        transactionUrl,
        isClaiming,
    ] = useSelector((state: any) => [
        state.claim.limeAvailable,
        state.claim.limeClaimed,
        state.claim.isLoading,
        state.claim.error,
        state.claim.isSuccess,
        state.claim.transactionUrl,
        state.claim.isClaiming,
    ]);
    const [wallet, chainId] = useSelector((state: AppStateType) => [
        state.services.wallet,
        state.services.providerE?._network?.chainId,
    ]);

    const buttonClickHandler = () => {
        if (isLoading || isClaiming) {
            return;
        }
        if (wallet) {
            dispatch(getClaim());
            return;
        }

        modalShow(true);
    };

    const buttonText = () => {
        if (isLoading || isClaiming)
            return <div className={'loading-circle'} />;
        if (!wallet) {
            return 'Connect Wallet';
        }
        return `Claim ${COIN_LIME}`;
    };

    useEffect(() => {
        if (wallet) dispatch(loadContractInfo());
    }, [wallet]);

    return (
        <div className={'claim service-wrapper'}>
            <div className={'field-wrapper'}>
                <div className={'toast-wrapper'}>
                    <Toast isHidden={!!wallet}>
                        <img src={infoPic} alt={'info'} />
                        <div>
                            To perform actions on this page, connect your wallet
                        </div>
                    </Toast>
                </div>

                <div className={'toast-wrapper'}>
                    {wallet &&
                    chainId !== claimConfig[currentNetwork].provider.chainId ? (
                        <Toast isError>
                            <img src={errorPic} alt={'error'} />

                            <div>{`You selected wrong network. Select ${claimConfig[currentNetwork].provider.name} in your provider and refresh the page`}</div>
                        </Toast>
                    ) : (
                        <Toast isError isHidden={!error}>
                            <img src={errorPic} alt={'error'} />

                            <div>{error}</div>
                        </Toast>
                    )}
                </div>

                <div
                    className={'toast-wrapper'}
                    onClick={() => openNewTab(transactionUrl)!.focus()}
                >
                    <Toast isSuccess isHidden={!(transactionUrl && isSuccess)}>
                        <img src={tickCircle} alt={'success'} />
                        <div>
                            Transaction confirmed! Click <span>here</span> to
                            see it
                        </div>
                    </Toast>
                </div>

                <div className={'toast-wrapper'}>
                    <Toast isSent isHidden={!(transactionUrl && !isSuccess)}>
                        <div>Transaction sent, please wait...</div>
                    </Toast>
                </div>

                <div className={'field'}>
                    <div className={'content'}>
                        <div className={'title'}>{PROJECT_SHORT_NAME} Lab</div>

                        <div className={'chain'}>
                            <div className={'logo'}>
                                <Bep20 />
                            </div>

                            <div className={'info'}>
                                <div className={'name'}>BNB Chain</div>
                                <div className={'second-name'}>Lime BEP20</div>
                            </div>
                        </div>

                        <div className={'lime-amount'}>
                            <div className={'label'}>
                                Available: {COIN_LIME}
                            </div>
                            {isLoading || !wallet ? (
                                <div className="ssc-line" />
                            ) : (
                                <div className={'amount'}>
                                    {BNToNumstr(limeAvailable)} {COIN_LIME}
                                </div>
                            )}
                        </div>

                        <div className={'lime-amount'}>
                            <div className={'label'}>Claimed: {COIN_LIME}</div>
                            {isLoading || !wallet ? (
                                <div className="ssc-line" />
                            ) : (
                                <div className={'amount'}>
                                    {BNToNumstr(limeClaimed)} {COIN_LIME}
                                </div>
                            )}
                        </div>

                        <Button
                            isBlueFill
                            isFullWidth
                            isDisabled={
                                wallet &&
                                chainId !==
                                    claimConfig[currentNetwork].provider.chainId
                            }
                            onClick={() => {
                                buttonClickHandler();
                            }}
                        >
                            {buttonText()}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Claim;
