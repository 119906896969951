import {useHistory} from 'react-router-dom';
import {LINK_PREMIUM_DEEPLINK} from 'config';
import {ROUTE_MAIN} from 'routes/names';

export default function PremiumDeepLink() {
    const history = useHistory();
    const navigator: any = window.navigator.userAgent;

    if (/android|iphone|ipad|Win|Mac|Linux/i.test(navigator)) {
        window.location.href = LINK_PREMIUM_DEEPLINK;
        history.push(ROUTE_MAIN);
    } else {
        history.push(ROUTE_MAIN);
    }

    return null;
}
