import { FC, useEffect, useRef, useState } from 'react';
import './style.scss';
import { ReactComponent as Logo } from 'assets/img/logo-full.svg';
import { Button } from '../form/Button';
import { ReactComponent as DropdownPic } from 'assets/img/up-small.svg';
import { ReactComponent as Burger } from 'assets/img/buttons/burger-menu.svg';
import Modal from '../Modal';
import HeaderMenu from './HeaderMenu';
import { LINK_APP_STORE, SCROLL_TO_CONTACTS, SCROLL_TO_FEATURES } from 'config';
import {
    ROUTE_AIRDROP,
    ROUTE_BRIDGE,
    ROUTE_CATALOG,
    ROUTE_DOWNLOAD_DESKTOP,
    ROUTE_DOWNLOAD_ANDROID,
    ROUTE_EXCHANGE,
    ROUTE_MAIN,
    ROUTE_STAKING,
} from 'routes/names';
import { useHistory } from 'react-router-dom';
import { setScrollTo } from 'modules/Landing';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { ReactComponent as Telegram } from 'assets/img/landing/about/telegram.svg';
import { AppStateType } from 'store';
// @ts-ignore
import TelegramLoginButton from 'react-telegram-login';
import { setTelegramButton, telegramLogin } from 'modules/System';
import { isTablet, openNewTab } from 'utils';
import HeaderUserDropdown from './HeaderUserDropdown';

interface IHeader {
    isLogoVisible?: boolean;
}

const Header: FC<IHeader> = ({ isLogoVisible }) => {
    const history = useHistory();
    const [isShown, setIsShow] = useState<boolean>(false);
    const account = useSelector((state: AppStateType) => state.system.account);
    const [accountDropdown, setAccountDropdown] = useState<boolean>(false);
    const dispatch = useDispatch();

    const handleTelegramResponse = async (response: any) => {
        dispatch(telegramLogin(response));
    };

    const updateWidth = () => {
        setSize(window.innerWidth);
    };

    const [size, setSize] = useState<number>(0);

    useEffect(() => {
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    const refMyButton = useRef<HTMLDivElement>(null);
    const refTelegramIframe = useRef<HTMLDivElement>(null);
    const loginButton = useSelector(
        (state: AppStateType) => state.system.telegramButton
    );

    useEffect(() => {
        if (!account && loginButton) {
            const width = loginButton?.width;
            const height = loginButton?.height;
            const left = loginButton?.x;
            const top = loginButton?.y;

            refTelegramIframe.current!.style.width = width + 'px';
            refTelegramIframe.current!.style.height = height + 'px';
            refTelegramIframe.current!.style.left = left + 'px';
            refTelegramIframe.current!.style.top = top + 'px';
            refTelegramIframe.current!.style.position =
                loginButton?.position || '';
        }
    }, [loginButton]);

    useEffect(() => {
        if (!account && isTablet()) {
            const width = refMyButton.current?.offsetWidth;
            const height = refMyButton.current?.offsetHeight;
            const left = refMyButton.current?.offsetLeft;
            const top = refMyButton.current?.offsetTop;

            refTelegramIframe.current!.style.width = width + 'px';
            refTelegramIframe.current!.style.height = height + 'px';
            refTelegramIframe.current!.style.left = left + 'px';
            refTelegramIframe.current!.style.top = top + 'px';
            refTelegramIframe.current!.style.position =
                loginButton?.position || '';
        }
    }, [size]);

    const loginHover = () => {
        dispatch(
            setTelegramButton({
                x: refMyButton.current?.offsetLeft,
                y: refMyButton.current?.offsetTop,
                width: refMyButton.current?.offsetWidth,
                height: refMyButton.current?.offsetHeight,
                position: 'fixed',
                hover: true,
            })
        );
        refMyButton.current!.className = 'login-button hover';
    };
    const loginUnhover = () => {
        refMyButton.current!.className = 'login-button';
        dispatch(
            setTelegramButton({
                x: refMyButton.current?.offsetLeft,
                y: refMyButton.current?.offsetTop,
                width: 0,
                height: 0,
                position: loginButton?.position || '',
                hover: false,
            })
        );
    };

    useEffect(() => {
        if (loginButton?.position === 'absolute') {
            if (!loginButton?.hover)
                refMyButton.current!.className = 'login-button';
        }
    }, [loginButton]);

    return (
        <div
            className={classNames({
                header: true,
                transparent:
                    (isLogoVisible || window.pageYOffset < 100) &&
                    history.location.pathname === ROUTE_MAIN,
            })}
        >
            <Modal isShown={isShown}>
                <HeaderMenu hide={() => setIsShow(false)} />
            </Modal>
            <div
                className={'logo visible'}
                onClick={() => {
                    history.push(ROUTE_MAIN);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
            >
                <Logo />
            </div>

            <div className={'buttons'}>
                <Button
                    isBlack
                    onClick={() => {
                        history.push(ROUTE_MAIN);
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                >
                    Home
                </Button>
                <div className={'dropdown'}>
                    <div className={'dropdown-button'}>
                        <Button isBlack>
                            Apps
                            <DropdownPic />
                        </Button>
                    </div>
                    <div className="dropdown-list">
                        <div
                            className="dropdown-item"
                            onClick={() => {
                                openNewTab(LINK_APP_STORE);
                            }}
                        >
                            iOS
                        </div>
                        <div
                            className="dropdown-item"
                            onClick={() => {
                                history.push(ROUTE_DOWNLOAD_ANDROID);
                            }}
                        >
                            Android
                        </div>
                        <div
                            className="dropdown-item"
                            onClick={() => history.push(ROUTE_DOWNLOAD_DESKTOP)}
                        >
                            Desktop
                        </div>
                    </div>
                </div>
                <Button
                    isBlack
                    onClick={() => {
                        history.push(ROUTE_MAIN);
                        dispatch(setScrollTo(SCROLL_TO_FEATURES));
                    }}
                >
                    Features
                </Button>
                <div className={'dropdown'}>
                    <div className={'dropdown-button'}>
                        <Button isBlack>
                            Services
                            <DropdownPic />
                        </Button>
                    </div>
                    <div className="dropdown-list">
                        <div
                            className="dropdown-item"
                            onClick={() => history.push(ROUTE_EXCHANGE)}
                        >
                            Exchange
                        </div>
                        <div
                            className="dropdown-item"
                            onClick={() => history.push(ROUTE_BRIDGE)}
                        >
                            Bridge
                        </div>
                        {/* <div
                            className="dropdown-item"
                            onClick={() => history.push(ROUTE_STAKING)}
                        >
                            Staking
                        </div> */}
                        <div
                            className="dropdown-item"
                            onClick={() => history.push(ROUTE_AIRDROP)}
                        >
                            Multi-transfer
                        </div>
                        <div className="dropdown-item">
                            Neurobots
                            <div className={'soon-tag'}>soon</div>
                        </div>
                        <div
                            className="dropdown-item"
                            onClick={() => history.push(ROUTE_CATALOG)}
                        >
                            Catalog
                        </div>
                    </div>
                </div>
                <Button
                    isBlack
                    onClick={() => {
                        history.push(ROUTE_MAIN);
                        dispatch(setScrollTo(SCROLL_TO_CONTACTS));
                    }}
                >
                    Support
                </Button>
            </div>

            {account ? (
                <div
                    className={'account-logged'}
                    onClick={() => setAccountDropdown(!accountDropdown)}
                >
                    {account.photo_url && (
                        <img
                            className={'avatar'}
                            src={account.photo_url}
                            alt={'avatar'}
                        />
                    )}
                    {accountDropdown ? (
                        <HeaderUserDropdown
                            account={account}
                            close={() => setAccountDropdown(false)}
                        />
                    ) : null}
                </div>
            ) : (
                <div onMouseLeave={() => loginUnhover()}>
                    <div
                        className={'login-button'}
                        ref={refMyButton}
                        onMouseEnter={() => loginHover()}
                    >
                        <Button isBlueFill>
                            <div className={'full'}>Login&nbsp;via&nbsp;Telegram</div>
                            <div className={'short'}>
                                <Telegram />
                                Login
                            </div>
                        </Button>
                    </div>
                    <div className={'telegram-iframe'} ref={refTelegramIframe}>
                        <TelegramLoginButton
                            cornerRadius={'0px'}
                            usePic={false}
                            dataOnauth={handleTelegramResponse}
                            botName={process.env.REACT_APP_TELEGRAM_BOT_NAME}
                        />
                    </div>
                </div>
            )}

            <div className={'burger'} onClick={() => setIsShow(true)}>
                <Burger />
            </div>
        </div>
    );
};

export default Header;
