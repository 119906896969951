import { FC } from 'react'
import './style.scss'
import '../../style.scss'
import classNames from 'classnames'

interface ICatalogButtonsSmall {
    buttons: Array<string>
    selectedIndex: number
    setSelectedIndex: (index: number) => void
}

const CatalogButtonsSmall: FC<ICatalogButtonsSmall> = ({
    buttons,
    selectedIndex,
    setSelectedIndex,
}) => {
    return (
        <div className={'catalog-buttons-small'}>
            {buttons.map((i, index) => (
                <div
                    key={index}
                    className={classNames({
                        'catalog-button': true,
                        selected: selectedIndex === index,
                    })}
                    onClick={() => setSelectedIndex(index)}
                >
                    {i}
                </div>
            ))}
        </div>
    )
}

export default CatalogButtonsSmall
