import { FC, Fragment, useEffect, useRef } from 'react';
import '../../style.scss';
import { CatalogCategory } from 'types';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateType } from 'store';
import CategoriesTags from '../CategoriesTags';
import CatalogCard from '../CatalogCard';
import {
    getAllChannels,
    setCurrentCategoryChannels,
    setSelectedTagIndex,
} from 'modules/Catalog';
import { useOnScreen } from 'hooks';
import { Button } from '../../../form/Button';
import EmptyContent from '../EmptyContent';
import { CATALOG_PREVIEW_CATEGORY_SIZE } from 'config';
import { ROUTE_CATALOG } from 'routes/names';
import { useHistory } from 'react-router-dom';
import { CategoriesTagsChannelsProps } from './types';

const CategoriesTagsChannels: FC<CategoriesTagsChannelsProps> = ({
    categories,
}) => {
    const dispatch = useDispatch();
    const currentCategory = useSelector(
        (state: AppStateType) =>
            state.catalog.currentCategory[state.catalog.currentMode]
    );
    const allChannels = useSelector(
        (state: AppStateType) =>
            state.catalog.allChannels[state.catalog.currentMode]
    );
    const categoryChannels = useSelector(
        (state: AppStateType) =>
            state.catalog.currentCategoryChannels[state.catalog.currentMode]
    );
    const isCategory = useSelector(
        (state: AppStateType) =>
            state.catalog.isCategory[state.catalog.currentMode]
    );
    const isLoading = useSelector(
        (state: AppStateType) => state.catalog.isLoading
    );
    const isLoadingPreview = useSelector(
        (state: AppStateType) => state.catalog.isLoadingAll
    );
    const getAll = () => dispatch(getAllChannels());

    const ref = useRef<HTMLDivElement>(null);
    const needLoad = useOnScreen(ref);

    const cursor = useSelector(
        (state: AppStateType) =>
            state.catalog.previewCursor[state.catalog.currentMode]
    );

    useEffect(() => {
        if (
            needLoad &&
            cursor &&
            !isLoading &&
            !isLoadingPreview &&
            !isCategory
        )
            getAll();
    }, [needLoad]);

    const currentMode = useSelector(
        (state: AppStateType) => state.catalog.currentMode
    );
    const history = useHistory();

    return (
        <Fragment>
            {!allChannels || (allChannels && allChannels?.length > 0) ? (
                <CategoriesTags tags={categories} getAll={getAll} />
            ) : (
                <div style={{ marginTop: '30px' }}>
                    <EmptyContent />
                </div>
            )}
            {isLoading || (isLoadingPreview && !allChannels) ? (
                <div className={'loader-wrapper'}>
                    <div className={'loading-circle blue'} />{' '}
                </div>
            ) : (
                <Fragment>
                    {!isCategory ? (
                        allChannels?.map((i) => (
                            <Fragment key={i.category.id}>
                                <div
                                    key={i.category.id}
                                    style={{ width: '100%' }}
                                >
                                    <div className={'category-title'}>
                                        {i?.category.title}{' '}
                                        {i?.total >
                                            CATALOG_PREVIEW_CATEGORY_SIZE && (
                                            <Button
                                                isBlueFill
                                                onClick={() => {
                                                    dispatch(
                                                        setSelectedTagIndex(
                                                            categories?.findIndex(
                                                                (j) =>
                                                                    j.category
                                                                        .id ===
                                                                    i.category
                                                                        .id
                                                            )!
                                                        )
                                                    );
                                                    dispatch(
                                                        setCurrentCategoryChannels(
                                                            i,
                                                            i.total!
                                                        )
                                                    );
                                                    history.push(ROUTE_CATALOG);
                                                }}
                                            >
                                                Show all
                                            </Button>
                                        )}
                                    </div>

                                    <div className={'grid'}>
                                        {i.items?.map((i) => (
                                            <CatalogCard
                                                key={i.id}
                                                campaign={i}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </Fragment>
                        ))
                    ) : (
                        <Fragment>
                            <div style={{ width: '100%' }}>
                                <div className={'category-title'}>
                                    {currentCategory?.category.title}
                                </div>

                                <div className={'grid'}>
                                    {categoryChannels?.map((i) => (
                                        <CatalogCard key={i.id} campaign={i} />
                                    ))}
                                </div>
                            </div>
                        </Fragment>
                    )}
                </Fragment>
            )}
            {isLoadingPreview && allChannels?.length! > 0 && (
                <div className={'loader-wrapper'}>
                    <div className={'loading-circle blue'} />{' '}
                </div>
            )}

            <div ref={ref} style={{ width: '10px', height: '10x' }}></div>
        </Fragment>
    );
};

export default CategoriesTagsChannels;
