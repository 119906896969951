import { FC } from 'react'
import './style.scss'
import { ReactComponent as AppStore } from '../../assets/img/buttons/apple-footer.svg'
import { ReactComponent as GooglePlay } from '../../assets/img/buttons/google-footer.svg'
import { ReactComponent as Apk } from '../../assets/img/buttons/apk-footer.svg'
import { ReactComponent as Desktop } from '../../assets/img/buttons/desktop-footer.svg'
import {
    LINK_APK,
    LINK_APP_STORE,
    LINK_BRAND_ASSETS,
    LINK_GOOGLE_PLAY,
    LINK_LEGAL_OPINION,
    LINK_LIME_AUDIT,
    LINK_POLICY,
    LINK_TELEGRAM_DISCUSS_IME,
    LINK_TELEGRAM_DISCUSS_LIME,
    LINK_TELEGRAM_NEWS, LINK_TELEGRAM_RU_NEWS,
    LINK_TELEGRAM_SUPPORT, LINK_TELEGRAM_WALLET_BOT,
    LINK_USER_AGREEMENT,
    PROJECT_SHORT_NAME,
    SCROLL_TO_ABOUT,
    SCROLL_TO_COLLAB,
    SCROLL_TO_FEATURES,
    SCROLL_TO_LIME,
    // SCROLL_TO_ROADMAP,
} from 'config';
import {
    ROUTE_AIRDROP,
    ROUTE_BRIDGE,
    ROUTE_CATALOG,
    ROUTE_DOWNLOAD_DESKTOP,
    ROUTE_EXCHANGE,
    ROUTE_MAIN,
    ROUTE_STAKING,
    ROUTE_DOWNLOAD_ANDROID,
} from 'routes/names';
import { openNewTab } from 'utils';
import { useHistory } from 'react-router-dom';
import { PROJECT_NAME } from 'config';
import { useDispatch } from 'react-redux';
import { setScrollTo } from 'modules/Landing';

const Footer: FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const withScroll = (target: string) => {
        history.push(ROUTE_MAIN);
        dispatch(setScrollTo(target));
    };

    return (
        <div className={'footer'}>
            <div className={'info'}>
                <div className={'copyright'}>
                    {'©' + new Date().getFullYear() + ' ' + PROJECT_NAME}
                </div>
                <div className={'downloads'}>
                    <div className={'title'}>Download our Apps</div>
                    <div className={'buttons'}>
                        <div className="buttons-block">
                            <div className={'downloadButton'}>
                                <AppStore
                                    onClick={() => {
                                        openNewTab(LINK_APP_STORE);
                                    }}
                                />
                            </div>
                            <div className="downloadButton">
                                <GooglePlay
                                    onClick={() => {
                                        openNewTab(LINK_GOOGLE_PLAY);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="buttons-block">
                            <div className="downloadButton">
                                <Apk
                                    onClick={() => history.push(ROUTE_DOWNLOAD_ANDROID)}
                                />
                            </div>
                            <div className="downloadButton">
                                <Desktop
                                    onClick={() => history.push(ROUTE_DOWNLOAD_DESKTOP)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'links'}>
                <div className={'block'}>
                    <div>
                        <div className={'title'}>Main</div>
                        <div className={'links-wrapper'}>
                            <div onClick={() => withScroll(SCROLL_TO_ABOUT)}>
                                About
                            </div>
                            <div onClick={() => withScroll(SCROLL_TO_LIME)}>
                                Lime
                            </div>
                            <div onClick={() => withScroll(SCROLL_TO_FEATURES)}>
                                Features
                            </div>
                            {/*<div onClick={() => withScroll(SCROLL_TO_ROADMAP)}>*/}
                            {/*    Roadmap*/}
                            {/*</div>*/}
                            <div onClick={() => withScroll(SCROLL_TO_COLLAB)}>
                                Collaborations
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'block'}>
                    <div>
                        <div className={'title'}>Services</div>
                        <div className={'links-wrapper'}>
                            <div onClick={() => history.push(ROUTE_EXCHANGE)}>
                                Exchange
                            </div>
                            <div onClick={() => history.push(ROUTE_BRIDGE)}>
                                Bridge
                            </div>
                            {/* <div onClick={() => history.push(ROUTE_STAKING)}>
                                Staking
                            </div> */}
                            <div onClick={() => history.push(ROUTE_AIRDROP)}>
                                Multi-transfer
                            </div>
                            <div>
                                Neurobots<div className={'soon-tag'}>soon</div>
                            </div>
                            <div onClick={() => history.push(ROUTE_CATALOG)}>
                                Catalog
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'block third'}>
                    <div>
                        <div className={'title'}>Company</div>
                        <div className={'links-wrapper'}>
                            <div
                                onClick={() => openNewTab(LINK_USER_AGREEMENT)}
                            >
                                User Agreement
                            </div>
                            <div onClick={() => openNewTab(LINK_POLICY)}>
                                Privacy Policy
                            </div>
                            <div onClick={() => openNewTab(LINK_LEGAL_OPINION)}>
                                Lime Legal Opinion
                            </div>
                            <div onClick={() => openNewTab(LINK_LIME_AUDIT)}>
                                Lime Audit
                            </div>
                            <div onClick={() => openNewTab(LINK_BRAND_ASSETS)}>
                                Brand Assets
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'block four'}>
                    <div>
                        <div className={'title'}>Support</div>
                        <div className={'links-wrapper'}>
                            <div onClick={() => openNewTab(LINK_TELEGRAM_NEWS)}>
                                News Channel En
                            </div>
                            <div onClick={() => openNewTab(LINK_TELEGRAM_RU_NEWS)}>
                                News Channel Ru
                            </div>
                            <div
                                onClick={() =>
                                    openNewTab(LINK_TELEGRAM_DISCUSS_IME)
                                }
                            >
                                Discuss {PROJECT_SHORT_NAME} App
                            </div>
                            <div
                                onClick={() =>
                                    openNewTab(LINK_TELEGRAM_DISCUSS_LIME)
                                }
                            >
                                Discuss Lime Token
                            </div>
                            <div onClick={() => openNewTab(LINK_TELEGRAM_WALLET_BOT)}>
                                Wallet Bot
                            </div>
                            <div
                                onClick={() =>
                                    openNewTab(LINK_TELEGRAM_SUPPORT)
                                }
                            >
                                Support
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
