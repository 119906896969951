import { FC, useEffect, useRef } from 'react';
import './style.scss';
import '../../style.scss';
import { Campaign } from 'types';
import CatalogCard from '../CatalogCard';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateType } from 'store';
import { getPopularChannels } from 'modules/Catalog';
import { useOnScreen } from 'hooks';
import EmptyContent from '../EmptyContent';

const CatalogPopular: FC = () => {
    const dispatch = useDispatch();
    const currentMode = useSelector(
        (state: AppStateType) => state.catalog.currentMode
    );
    const populars = useSelector(
        (state: AppStateType) =>
            state.catalog.popularChannels[state.catalog.currentMode]
    );
    const isLoading = useSelector(
        (state: AppStateType) => state.catalog.isLoadingPopular
    );

    const language = useSelector(
        (state: AppStateType) => state.catalog.currentLanguageId
    );

    useEffect(() => {
        if (!populars) dispatch(getPopularChannels());
    }, [currentMode, language]);

    const ref = useRef<HTMLDivElement>(null);
    const needLoad = useOnScreen(ref);

    const cursor = useSelector(
        (state: AppStateType) =>
            state.catalog.popularCursor[state.catalog.currentMode]
    );

    useEffect(() => {
        if (needLoad && cursor && !isLoading) dispatch(getPopularChannels());
    }, [needLoad]);
    return (
        <div className={'popular'}>
            {isLoading && !populars ? (
                <div className={'loading-circle blue'} />
            ) : (
                <div className={'grid'}>
                    {' '}
                    {populars &&
                        populars.length > 0 &&
                        populars.map((i: Campaign, index: number) => (
                            <CatalogCard key={index} campaign={i} />
                        ))}
                </div>
            )}

            {isLoading && populars && populars?.length > 0 ? (
                <div className={'loading-circle blue'} />
            ) : null}
            <div ref={ref} style={{ width: '10px', height: '10x' }}></div>

            {!isLoading && populars && populars.length === 0 ? (
                <EmptyContent />
            ) : null}
        </div>
    );
};

export default CatalogPopular;
