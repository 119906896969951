import { FC, Fragment, useState } from 'react';
import HeaderService from '../components/Header/HeaderService';
import Modal from '../components/Modal';
import WalletConnect from '../components/Modal/WalletConnect';
import {
    clickConnectMetamask,
    clickConnectWalletconnect,
} from '../modules/Services';
import Footer from '../components/Footer';
import Claim from '../components/Service/Claim';

const ClaimPage: FC = () => {
    const [isShown, setIsShown] = useState<boolean>(false);
    return (
        <Fragment>
            <Modal isShown={isShown} hide={() => setIsShown(false)}>
                <WalletConnect
                    clickMetamask={clickConnectMetamask}
                    clickWalletConnect={clickConnectWalletconnect}
                    hide={() => setIsShown(false)}
                />
            </Modal>

            <div className={'flex-container-wrapper'}>
                <HeaderService modalShow={setIsShown} />
                <Claim modalShow={() => setIsShown(!isShown)} />
                <div className={'footer-wrapper'}>
                    <Footer />
                </div>
            </div>
        </Fragment>
    );
};

export default ClaimPage;
