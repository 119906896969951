import { FC, useEffect, useRef } from 'react';
import './style.scss';
import '../../style.scss';
import { Button } from '../../../form/Button';
import CatalogCard from '../CatalogCard';
import { useHistory } from 'react-router-dom';
import { ROUTE_CHANNEL_CREATE } from 'routes/names';
import plus from 'assets/img/plus-circle.svg';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateType } from 'store';
import { createChannelClear, getUserChannels } from 'modules/Catalog';
import { setTelegramButton } from 'modules/System';
import { isTablet } from 'utils';

const MyCatalog: FC = () => {
    const isLogin = useSelector((state: AppStateType) => state.system.account);

    const myChannels = useSelector(
        (state: AppStateType) => state.catalog.userChannels
    );
    const currentMode = useSelector(
        (state: AppStateType) => state.catalog.currentMode
    );
    const text = () => {
        if (myChannels && myChannels.length > 0) {
            return null;
        }

        return 'You don’t have own channels yet.\nYour own channels can be controlled through the Cabinet.';
    };

    const history = useHistory();

    const dispatch = useDispatch();
    useEffect(() => {
        if (!myChannels && isLogin) {
            dispatch(getUserChannels());
        }
    }, []);

    const refMyButton = useRef<HTMLDivElement>(null);

    const loginButton = useSelector(
        (state: AppStateType) => state.system.telegramButton
    );

    const loginHover = () => {
        dispatch(
            setTelegramButton({
                x: refMyButton.current?.offsetLeft,
                y: refMyButton.current?.offsetTop,
                width: refMyButton.current?.offsetWidth,
                height: refMyButton.current?.offsetHeight,
                position: 'absolute',
                hover: true,
            })
        );
        refMyButton.current!.className = 'login-button hover';
    };

    useEffect(() => {
        if (loginButton?.position === 'absolute') {
            if (!loginButton?.hover)
                refMyButton.current!.className = 'login-button';
        }
    }, [loginButton]);

    const isLoading = useSelector(
        (state: AppStateType) => state.catalog.isLoadingUsers
    );
    return (
        <div className={'my-catalog'}>
            {isLogin ? (
                isLoading ? (
                    <div className={'loading-circle blue'} />
                ) : (
                    <div className={'login'}>
                        {text() ? (
                            <div className={'text'}>
                                {text()}
                                <Button
                                    isBlueFill
                                    onClick={() => {
                                        dispatch(createChannelClear());
                                        history.push(ROUTE_CHANNEL_CREATE);
                                    }}
                                >
                                    Add new channel
                                </Button>
                            </div>
                        ) : null}

                        {myChannels && myChannels.length > 0 ? (
                            <div className={'own block'}>
                                <div className={'title'}>
                                    Own {currentMode}s{' '}
                                </div>

                                <div className={'grid'}>
                                    <div
                                        className={'catalog-card new-channel'}
                                        onClick={() => {
                                            dispatch(createChannelClear());
                                            history.push(ROUTE_CHANNEL_CREATE);
                                        }}
                                    >
                                        <img src={plus} alt={'add'} />
                                        New Channel
                                    </div>

                                    {myChannels
                                        ?.filter(
                                            (i) =>
                                                i.channel.chatType.toUpperCase() ===
                                                currentMode.toUpperCase()
                                        )
                                        .map((i, index) => (
                                            <CatalogCard
                                                key={index}
                                                campaign={i}
                                            />
                                        ))}
                                </div>
                            </div>
                        ) : null}
                    </div>
                )
            ) : (
                <div className={'no-login'}>
                    <div className={'text'}>
                        To see and to your own channels you need to login via
                        Telegram. Don’t worry, it’s secure.
                    </div>

                    {!isTablet() && (
                        <div
                            className={'login-button'}
                            ref={refMyButton}
                            onMouseEnter={() => {
                                loginHover();
                            }}
                        >
                            <Button isBlueFill>Login via Telegram</Button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default MyCatalog;
