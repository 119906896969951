import { FC, useState } from 'react';
import './style.scss';
import '../style.scss';
import ExchangeCard from './ExchangeCard';
import uniswap from 'assets/img/landing/Lime/horse.svg';
import pancake from 'assets/img/landing/Lime/brown.svg';
import apeswap from 'assets/img/landing/Lime/apeSwap.svg';
import gateio from 'assets/img/landing/Lime/sq.svg';
import ascendex from 'assets/img/landing/Lime/ascendex2.svg';
import sushiSwap from 'assets/img/landing/Lime/sushiSwap.svg';
import uniswapV3 from 'assets/img/landing/Lime/uniswapV3.svg';
import {
    LINK_LIME_APESWAP,
    LINK_LIME_ASCENDEX,
    LINK_LIME_GATE_ETH,
    LINK_LIME_PANCAKE,
    LINK_SUSHISWAP,
    LINK_UNISWAPV3,
} from 'config';
import { Button } from '../../form/Button';

const Exchange: FC = () => {
    const LIME_BEP = 'LIME BEP20';
    const LIME_ERC = 'LIME ERC20';
    const LIME_POLYGON = 'LIME POLYGON';
    const COLOR_BEP = '#FECC71';
    const COLOR_ERC = '#B8D1EF';
    const COLOR_POLYGON = '#8853DF';

    const [isPopup, setIsPopup] = useState<boolean>(false);
    const [clickY, setClickY] = useState<number>(0);

    return (
        <div className={'exchange service-wrapper'}>
            {isPopup ? (
                <div
                    className={'popup-wrapper'}
                    onClick={() => {
                        setIsPopup(false);
                    }}
                >
                    <div
                        className={'popup'}
                        style={{ top: clickY }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        Liquidity for LIME ERC20 is not available, switch to
                        using LIME BEP20.
                        <Button
                            isBlue
                            onClick={() => {
                                setIsPopup(false);
                            }}
                        >
                            OK
                        </Button>
                    </div>
                </div>
            ) : null}
            <div className={'title'}>Exchange</div>

            <div className={'blocks'}>
                <div>
                    <div className={'block-title'}>DEX Swap</div>

                    <div
                        onClick={(e) => {
                            setClickY(e.clientY);
                            setIsPopup(true);
                        }}
                    >
                        <ExchangeCard
                            title={'Uniswap'}
                            pic={uniswap}
                            picColor={'#FFECF5'}
                            tags={[{ name: LIME_ERC, color: COLOR_ERC }]}
                            link={undefined}
                        />
                    </div>
                    <ExchangeCard
                        title={'PancakeSwap'}
                        pic={pancake}
                        picColor={'#FEDC904C'}
                        tags={[{ name: LIME_BEP, color: COLOR_BEP }]}
                        link={LINK_LIME_PANCAKE}
                    />
                    <ExchangeCard
                        title={'ApeSwap'}
                        pic={apeswap}
                        picColor={'#A1655226'}
                        tags={[{ name: LIME_BEP, color: COLOR_BEP }]}
                        link={LINK_LIME_APESWAP}
                    />

                    <ExchangeCard
                        title={'Uniswap V3'}
                        pic={uniswapV3}
                        picColor={'#0C2C541A'}
                        tags={[{ name: LIME_POLYGON, color: COLOR_POLYGON }]}
                        link={LINK_UNISWAPV3}
                    />
                    <ExchangeCard
                        title={'SushiSwap'}
                        pic={sushiSwap}
                        picColor={'#A576C133'}
                        tags={[{ name: LIME_POLYGON, color: COLOR_POLYGON }]}
                        link={LINK_SUSHISWAP}
                    />
                </div>

                <div>
                    <div className={'block-title'}>CEX Exchange</div>

                    <ExchangeCard
                        title={'Gate.io'}
                        pic={gateio}
                        picColor={'#DE595926'}
                        tags={[
                            { name: LIME_BEP, color: COLOR_BEP },
                            { name: LIME_POLYGON, color: COLOR_POLYGON },
                        ]}
                        link={LINK_LIME_GATE_ETH}
                    />
                    <ExchangeCard
                        title={'AscendEX'}
                        pic={ascendex}
                        picColor={'#3BD5EF4D'}
                        tags={[
                            { name: LIME_BEP, color: COLOR_BEP },
                            { name: LIME_POLYGON, color: COLOR_POLYGON },
                        ]}
                        link={LINK_LIME_ASCENDEX}
                    />
                </div>
            </div>
        </div>
    );
};

export default Exchange;
