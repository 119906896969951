import { FC, Fragment, useEffect, useState } from 'react';
import './style.scss';
import metamask from 'assets/img/metamask.svg';
import walletConnect from 'assets/img/wallet-connect.svg';
import disconnect from 'assets/img/x-circle-red.svg';
import cancel from 'assets/img/cancel.svg';
import tickPic from 'assets/img/tick-circle.svg';
import { useDispatch, useSelector } from 'react-redux';
import { resetWallets, WALLET_TYPE } from 'modules/Services';
import { WalletConnectProps } from './types';

const WalletConnect: FC<WalletConnectProps> = ({
    hide,
    clickMetamask,
    clickWalletConnect,
}) => {
    const [currentWallet, qr, wallet, error] = useSelector((state: any) => [
        state.services.connectType,
        state.services.walletConnectURI,
        state.services.wallet,
        state.services.error,
    ]);

    const dispatch = useDispatch();
    const [isShown, setIsShown] = useState<boolean>(false);
    const [walletConnectBtn, setWalletConnectBtn] = useState<boolean>(false);

    useEffect(() => {
        if (qr && walletConnectBtn) {
            setIsShown(true);
        }
        if (wallet && walletConnectBtn && currentWallet === WALLET_TYPE.WALLET_CONNECT) {
            hide();
        }

        if (walletConnectBtn && currentWallet === WALLET_TYPE.WALLET_CONNECT && !qr) {
            hide()
        }
    }, [qr, wallet, currentWallet, hide, walletConnectBtn, error]);

    return (
        <Fragment>
            <div
                className={'wallet-connect' + (isShown ? ' hide' : '')}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={'title'}>
                    <div>Connect using:</div>
                    <img src={cancel} alt={'cancel'} onClick={() => hide()} />
                </div>

                <div
                    className={'item-wrapper'}
                    onClick={() => {
                        dispatch(clickMetamask());
                        hide();
                    }}
                >
                    <div
                        className={
                            'item' +
                            (currentWallet === WALLET_TYPE.METAMASK && wallet ? ' selected' : '')
                        }
                    >
                        <div>
                            <img src={metamask} alt={'metamask'} />
                            MetaMask
                        </div>
                        {currentWallet === WALLET_TYPE.METAMASK && wallet ? (
                            <img src={tickPic} alt={'selected'} />
                        ) : null}
                    </div>
                </div>

                <div
                    className={'item-wrapper'}
                    onClick={() => {
                        dispatch(clickWalletConnect());
                        setWalletConnectBtn(true);
                    }}
                >
                    <div
                        className={
                            'item' +
                            (currentWallet === WALLET_TYPE.WALLET_CONNECT && wallet
                                ? ' selected'
                                : '')
                        }
                    >
                        <div>
                            <img src={walletConnect} alt={'wallet-connect'} />
                            WalletConnect
                        </div>
                        {currentWallet === WALLET_TYPE.WALLET_CONNECT && wallet ? (
                            <img src={tickPic} alt={'selected'} />
                        ) : null}
                    </div>
                </div>

                {wallet && (
                    <div
                        className={
                            'item disconnect' + (!wallet ? ' inactive' : '')
                        }
                        onClick={() => {
                            dispatch(resetWallets());
                            hide();
                        }}
                    >
                        <img src={disconnect} alt={'disconnect'} />
                        Disconnect Wallet
                    </div>
                )}

            </div>
        </Fragment>
    );
};

export default WalletConnect;
