import { BigNumber } from 'ethers';

export const removeTrailingZeros = (str: string): string => {
    if (str === '0') return str;
    if (str.slice(-1) === '0')
        return removeTrailingZeros(str.substr(0, str.length - 1));
    if (str.slice(-1) === '.') return str.substr(0, str.length - 1);
    return str;
};

export const BNToNumstr = (
    bn: BigNumber | string,
    dec = 18,
    prec = 3
): string => {
    if (!bn) return '0';
    const str = bn.toString();
    if (str === '0') return str;
    if (isNaN(Number(str))) return 'NaN';
    if (str.length <= dec)
        return removeTrailingZeros(
            (
                '0.' +
                '000000000000000000'.substr(0, dec - str.length) +
                str
            ).substr(0, dec - str.length + prec + 2)
        );
    else
        return removeTrailingZeros(
            [str.substr(0, str.length - dec), str.slice(-dec)]
                .join('.')
                .substr(0, str.length - dec + prec + 1)
        );
};

export const BNToNumstrStrict = (
    bn: BigNumber | string,
    dec = 18,
    prec = 3
): string => {
    if (!bn) return '0';
    const str = bn.toString();
    if (str === '0') return str;
    if (isNaN(Number(str))) return 'NaN';
    if (str.length <= dec)
        return removeTrailingZeros(
            (
                '0.' +
                '000000000000000000'.substr(0, dec - str.length) +
                str
            ).substr(0, dec - str.length + prec + 2)
        );
    else
        return [str.substr(0, str.length - dec), str.slice(-dec)]
            .join('.')
            .substr(0, str.length - dec + prec + 1);
};

export const numstrToBN = (input: string): BigNumber => {
    if (!input) return BigNumber.from('0');
    const spl = input.split('.');
    if (spl[1]) spl[1] = spl[1].substr(0, 18);
    return BigNumber.from(
        spl.join('') +
            '000000000000000000'.substr(0, 18 - (spl[1] || '').length)
    );
};
