import { FC } from 'react';
import './style.scss';
import '../../style.scss';
import person from 'assets/img/catalog/person.svg';
import { useDispatch } from 'react-redux';
import { setCurrentChannel } from 'modules/Catalog';
import { useHistory } from 'react-router-dom';
import { ROUTE_CHANNEL } from 'routes/names';
import { CARD_COLORS, CARD_STATUS } from './cardStatus';
import verified from 'assets/img/catalog/verified.svg';
import { CatalogCardProps } from './types';

const CatalogCard: FC<CatalogCardProps> = ({ campaign, isScroll }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const clickHandler = () => {
        if (!isScroll) {
            dispatch(setCurrentChannel(campaign));

            switch (campaign.status) {
                case CARD_STATUS.NOT_PAID:
                    history.push(
                        ROUTE_CHANNEL +
                            '/' +
                            campaign.channel.title +
                            '/' +
                            'checkout'
                    );
                    break;
                default:
                    history.push(
                        ROUTE_CHANNEL + '/' + campaign.channel.shortname
                    );
            }
        }
    };

    return (
        <div className={'catalog-card'} onClick={() => clickHandler()}>
            {campaign?.status && campaign.status !== CARD_STATUS.ACTIVE ? (
                <div
                    className={'status'}
                    style={{ background: CARD_COLORS[campaign.status] }}
                >
                    {campaign.status}
                </div>
            ) : null}
            <div className={'image-wrapper'}>
                <div className={'image-holder'}>
                    <img className={'image'} src={campaign?.channel.photo} />
                </div>
            </div>
            <div className={'info'}>
                <div className={'card-title'}>
                    {campaign?.channel.title}
                    {campaign?.channel.verified && <img src={verified} />}
                </div>
                <div>
                    {campaign?.category ? (
                        <div className={'tag'}>{campaign?.category.title}</div>
                    ) : null}
                    <div className={'rating'}>
                        <div>
                            {campaign?.channel.membersCount}
                            <img src={person} alt={'persons'} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CatalogCard;
