import { FC, useEffect, useRef, useState } from 'react';
import '../../style.scss';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
    setCurrentCategoryChannels,
    setIsCategory,
    setSelectedTagIndex,
} from 'modules/Catalog';
import { AppStateType } from 'store';
import { useHistory } from 'react-router-dom';
import { ROUTE_CATALOG } from 'routes/names';
import { CategoriesTagsProps } from './types';

const CategoriesTags: FC<CategoriesTagsProps> = ({ tags, getAll }) => {
    const [isAllCategoryTags, setIsAllCategoryTags] = useState<boolean>(false);

    const selectedCategoryTagIndex = useSelector(
        (state: AppStateType) =>
            state.catalog.selectedTagIndex[state.catalog.currentMode]
    );

    const isLoading = useSelector(
        (state: AppStateType) => state.catalog.isLoading
    );
    const isLoadingPreview = useSelector(
        (state: AppStateType) => state.catalog.isLoadingAll
    );
    const [width, setWidth] = useState<string>('100%');
    const ref = useRef<HTMLDivElement>(null);
    const [isToggle, setIsToggle] = useState<boolean>(false);

    const showAllTags = () => {
        setIsAllCategoryTags(!isAllCategoryTags);
    };
    const allChannels = useSelector(
        (state: AppStateType) =>
            state.catalog.allChannels[state.catalog.currentMode]
    );
    const currentMode = useSelector(
        (state: AppStateType) => state.catalog.currentMode
    );

    const language = useSelector(
        (state: AppStateType) => state.catalog.currentLanguageId
    );

    useEffect(() => {
        if (!allChannels) getAll();
    }, [currentMode, language]);

    useEffect(() => {
        const list = ref.current!.children[0]
            .children as unknown as Array<HTMLDivElement>;
        if (list[0].offsetTop === list[list.length - 1].offsetTop) {
            setIsToggle(false);
        } else {
            setIsToggle(true);
        }
    }, [tags, isLoadingPreview, isLoading, width]);

    const dispatch = useDispatch();
    const myHistory = useHistory();

    useEffect(() => {
        window.onpopstate = function () {
            dispatch(setSelectedTagIndex(-1));
            dispatch(setIsCategory(false));
        };

        return () => {
            window.onpopstate = () => {};
        };
    }, []);
    return (
        <div
            className={classNames({
                'categories-tags-wrapper': true,
                open: isAllCategoryTags,
                hidden: !allChannels,
            })}
            style={{ width: isLoading || isLoadingPreview ? width : '100%' }}
            ref={ref}
        >
            <div
                className={classNames({
                    'categories-tags': true,
                    open: isAllCategoryTags,
                })}
            >
                <div
                    className={classNames({
                        tag: true,
                        selected: selectedCategoryTagIndex === -1,
                    })}
                    onClick={() => {
                        dispatch(setSelectedTagIndex(-1));
                        dispatch(setIsCategory(false));
                    }}
                >
                    All {currentMode}s
                </div>
                {tags?.map((i, index) => (
                    <div
                        className={classNames({
                            tag: true,
                            selected: selectedCategoryTagIndex === index,
                        })}
                        onClick={() => {
                            setWidth(ref!.current!.offsetWidth + 'px');
                            dispatch(setSelectedTagIndex(index));
                            dispatch(setCurrentCategoryChannels(i, i.total!));
                            myHistory.push(ROUTE_CATALOG);
                        }}
                        key={index}
                    >
                        {i.category.title}
                    </div>
                ))}

                {isAllCategoryTags && isToggle ? (
                    <div className={'toggle'} onClick={() => showAllTags()}>
                        Show less
                    </div>
                ) : null}
            </div>
            {!isAllCategoryTags && isToggle ? (
                <div className={'toggle'} onClick={() => showAllTags()}>
                    More...
                </div>
            ) : null}
        </div>
    );
};

export default CategoriesTags;
